import React, { forwardRef } from 'react';

import { Container, Grid, Link, Typography } from '@mui/material';
import sectionStyles from './section.styles';

const nextDateImg = `${window.location.origin}/images/Fechas-v2.png`;

const NextDateSection = forwardRef((props, ref) => (
  <Container sx={sectionStyles.container} ref={ref}>
    <Grid container spacing={4}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
          Próximas Fechas
        </Typography>
      </Grid>
      <Grid item xs={12} container justifyContent="center">
        <Grid item xs={12} md={10}>
          <img
            src={nextDateImg}
            alt="Tabla de próximas fechas"
            style={{ width: '100%', height: 'auto', maxWidth: '100%' }}
          />
          <Typography gutterBottom sx={sectionStyles.subtitle}>
            IMPORTANTE:
          </Typography>
          {/* <Typography variant="subtitle2" gutterBottom>
            * La fecha de implementación no ha sido definida aún, pero tendrá lugar no antes del 29
            de octubre de 2024. Las demás etapas del calendario dependen de la fecha de
            implementación. Este sitio se actualizará tan pronto exista certeza sobre el calendario
            de implementación
          </Typography> */}
          <Typography variant="subtitle2" gutterBottom>
            * Para más información respecto al calendario de implementación y cada una de sus etapas
            usted puede consultar el acuerdo de conciliación <Link href="/#documents">aquí</Link>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  </Container>
));

export default NextDateSection;
