import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Container,
  Typography,
} from '@mui/material';
import React, { forwardRef } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import sectionStyles from './section.styles';

const FaqSection = forwardRef((props, ref) => {
  const dataFaq = [
    {
      id: 1,
      question: '¿De qué se trata el juicio?',
      answer:
        'Es un juicio colectivo de indemnización de perjuicios que nació luego de que el Tribunal de Defensa de la Libre Competencia y la Corte Suprema sancionaran a seis empresas navieras por haber infringido la libre competencia en determinadas licitaciones para transportar vehículos motorizados desde el extranjero hacia Chile.',
    },
    {
      id: 2,
      question: '¿Qué es la conciliación?',
      answer:
        'La conciliación es un método de resolver juicios en que las partes se ponen de acuerdo. Para proteger los derechos de los consumidores, la ley exige que en casos como este el tribunal apruebe la conciliación antes de que surta efecto.<br/> En este caso, la asociación de consumidores Arcam (demandante) llegó a un acuerdo con una de las demandadas. El texto de la conciliación está disponible aquí: <a download="acuerdo-de-conciliación.pdf" href="/documents/02%20Acuerdo%20de%20conciliaci%C3%B3n.pdf">Acuerdo de conciliación</a>. El Tribunal de Libre Competencia revisó los antecedentes y decidió que la conciliación respetaba los derechos de los consumidores según la Ley de Protección de los Derechos de los Consumidores. Esa decisión puede revisarse aquí: <a download="resolución-de-aprobación.pdf" href="documents/03%20Resoluci%C3%B3n%20aprobatoria.pdf">Resolución de aprobación de la conciliación</a>. El acuerdo termina el juicio respecto de esa demandada, pero seguirá respecto de las demás.',
    },
    {
      id: 3,
      question: '¿Quién es el administrador?',
      answer:
        'El administrador de la conciliación es la empresa consultora Deloitte Asesorías Legales y Tributarias Limitada.',
    },
    {
      id: 4,
      question: '¿Quiénes pueden solicitar el pago del Beneficio Individual ?',
      answer:
        'Para estar incluido en la conciliación (y poder solicitar el pago de un beneficio individual), usted tiene que haber sido el primer dueño de uno de los vehículos del listado <a download="listado-vehículos-elegibles.pdf" href="/documents/05%20Listado%20de%20veh%C3%ADculos%20elegibles.pdf">Listado de vehículos elegibles</a>. En ciertos casos excepcionales, la persona que aparezca como segundo dueño en el certificado puede, de todas formas, solicitar un beneficio individual (para más información, diríjase a la pregunta 6).<br/>Si su vehículo aparece en el listado, descargue el certificado de anotaciones vigentes desde el sitio del <a href="https://www.registrocivil.cl/principal/servicios-en-linea">Registro Civil</a>. <b>Asegúrese de que usted aparezca como el primer dueño.</b><br/>Ciertos vehículos de los años 2010, 2013, 2014 y 2015, pueden ser elegibles o no, dependiendo de la fecha de su primera inscripción en el Registro Nacional de Vehículos Motorizados. Esos vehículos están listados en las páginas 15 – 18 del <a download="listado-vehículos-elegibles.pdf" href="/documents/05%20Listado%20de%20veh%C3%ADculos%20elegibles.pdf">Listado de vehículos elegibles</a>.',
    },
    {
      id: 6,
      question: 'Si mi vehículo no está en el listado, ¿no tengo derecho al beneficio individual?',
      answer:
        'La conciliación incluye sólo vehículos de ciertos años, marcas y modelos. Si su vehículo no está incluido, usted no tiene derecho al beneficio individual.',
    },
    {
      id: 7,
      question:
        'Mi vehículo está en el listado, pero no aparezco como primer dueño en el padrón, sino que aparece la concesionaria a la que yo le compré el vehículo, ¿puedo igualmente pedir el beneficio individual?',
      answer:
        'Sí, puede. La regla general es que el beneficiario ocupe el primer lugar en el certificado de dominio del vehículo. Sin embargo, excepcionalmente, se acepta que ocupe el segundo lugar, pero solo cuando el primer lugar esté ocupado por un importador, distribuidor o comercializador de vehículos motorizados.',
    },
    {
      id: 8,
      question:
        'Mi vehículo actual está en el listado, pero yo lo compré usado. ¿Puedo pedir un beneficio?',
      answer:
        'No. La conciliación solo contempla pagos en dinero para las personas que acrediten haber sido los primeros dueños de los vehículos elegibles.',
    },
    {
      id: 9,
      question: '¿Qué pasa si soy beneficiario, pero no hago nada?',
      answer:
        'En ese caso, usted no recibirá un beneficio individual. Para evitar que pase eso, presente su solicitud de beneficio individual dentro de plazo, una vez que haya llegado la Fecha de Implementación.',
    },
    {
      id: 10,
      question: '¿A cuánto asciende el beneficio individual?',
      answer: 'El monto del beneficio individual es variable, pero no podrá exceder de 0,5 UF.',
    },
    {
      id: 11,
      question: '¿Cuánto tiempo tengo para presentar mi solicitud de beneficio individual?',
      answer:
        // 'El plazo para presentar solicitudes aún no comienza, y todavía no es posible saber exactamente el día en que empezará. En todo caso, no empezará antes del 29 de octubre de 2024. Ese plazo dura 4 meses. Para ver el calendario completo (que se actualizará periódicamente), consulte <a href="/#nextDates">Próximas Fechas</a>.',
        'Las solicitudes podrán presentarse entre el 18 de noviembre de 2024 y el 18 de marzo de 2025',
    },
    {
      id: 13,
      question:
        'He sido el primer dueño de más de un Vehículo Elegible, ¿Puedo solicitar el beneficio individual respecto de cada uno de ellos?',
      answer:
        'Sí, siempre y cuando se cumplan los requisitos que permiten solicitar el beneficio individual en cada caso.',
    },
    // {
    //   id: 14,
    //   question:
    //     '¿Qué ocurre si se presentan dos o más solicitudes respecto del mismo Vehículo Elegible?',
    //   answer:
    //     'En caso de que un Vehículo Elegible tenga o haya tenido dos o más copropietarios que califiquen como Beneficiarios, el pago realizado a un dueño constituirá un pago a todos ellos.',
    // },
    {
      id: 15,
      question: 'Presenté mi solicitud, ¿cómo puedo saber si funcionó?',
      answer:
        'El administrador de la conciliación tiene un plazo de hasta el 8 de abril de 2025 para comunicar si su solicitud ha sido aprobada u objetada. En este mismo sitio web usted podrá consultar el estado de su solicitud, ingresando a su cuenta.',
    },
    {
      id: 16,
      question: 'Objetaron mi solicitud, ¿qué hago?',
      answer:
        'Usted puede subsanar o enmendar los reparos que le haya formulado el administrador, dentro de un plazo de 10 días desde la comunicación respectiva por parte del Administrador.',
    },
    {
      id: 17,
      question: 'Presenté mi solicitud y fue aprobada. ¿Cuándo me pagarán?',
      answer:
        'El administrador tiene un plazo máximo para pagarle, que vence en Fecha Límite de Pago (13 de junio de 2025). Para más información, vea este calendario: <a href="/#nextDates">Próximas Fechas</a>.',
    },
    {
      id: 18,
      question: '¿Qué medios de pago están disponibles para el beneficio individual?',
      answer:
        'Se puede optar por transferencia electrónica o vale vista para retiro en una sucursal del Banco BCI. En este segundo caso, la persona que retire el vale vista tiene que demostrar, al momento de cobrarlo: (i) su identidad y, si es necesario, (ii) una copia auténtica del poder que lo autoriza a actuar en representación del beneficiario.',
    },
    {
      id: 19,
      question:
        'Una vez aprobada mi solicitud de beneficio individual, se me olvidó ir a cobrar mi vale vista. ¿Puedo hacerlo aún?',
      answer:
        'Si su solicitud ha sido aprobada, usted puede cobrar el beneficio individual hasta 13 de septiembre de 2025. Para más información sobre las fechas, siga este enlace: <a href="/#nextDates">Próximas Fechas</a>.<br/>',
    },
    // {
    //   id: 20,
    //   question: '¿Cómo me registro y completo la Solicitud del Beneficiario Individual?',
    //   answer:
    //     'En el menú principal de la página de inicio selecciones el botón "Iniciar sesión", luego seleccione el botón "Registrarse": <br/>- Escriba su nombre completo (2 nombres y 2 apellidos) <br/>- Correo electrónico <br/>- Contraseña <br/>- Repita contraseña <br/>- Seleccione el botón "Crear cuenta" Recibirá un correo electrónico confirmando su contraseña creada. <br/><br/>Una vez creada y confirmada la contraseña ir al menú principal de la página de inicio y seleccionar el botón "iniciar sesión" <br/>- Escriba el correo electrónico con el cual usted creó su contraseña <br/>- Digite su contraseña creada <br/>- Seleccione el botón "Iniciar sesión". Aquí ingresará a la plataforma para poder efectuar su Solicitud de Beneficio Individual <br/>- Seleccione el botón "Solicitudes" <br/>- Ingrese los datos requeridos. Todos los campos son obligatorios. <br/>- Al finalizar pinche el botón "Ingresar Solicitud", el sistema dará un mensaje que fue cargada exitosasamente. <br/>Una vez que haya completado su solicitud en línea, en la plataforma del sistema aparecerá como: <br/>- Estado INGRESADA <br/>Cuendo el usuario ingrese a la plataforma con su clave, podrá verificar el "Estado" de las solicitudes presentadas.',
    // },
    {
      id: 21,
      question: '¿Cómo puedo hacerle seguimiento a la solicitud que presenté?',
      answer:
        'Llegada la Fecha de Implementación, se habilitará en este mismo sitio web una pestaña en que usted podrá verificar periódicamente el estado de su solicitud, ingresando los datos de su cuenta.',
    },
    {
      id: 22,
      question: '¿Cómo puedo recuperar mi clave de acceso a la Solicitud?',
      answer:
        'En el menú principal de la página de inicio seleccione el botón "Iniciar sesión", luego seleccione el botón "Olvidé mi contraseña": <br/>- Escriba el correo electrónico con el cual usted creó su contraseña. <br/>- Seleccione el botón "Enviar link de recuperación". <br/>Se le enviará un correo electrónico con un link al que deberá ingresar para cambiar su contraseña.',
    },
    {
      id: 23,
      question: '¿Qué ocurre con los datos personales entregados en esta página web?',
      answer:
        'Los datos personales son tratados siguiendo las exigencias de la ley vigente. Para más información, consulte la <a download="politica-de-privacidad" href="/documents/06%20Pol%C3%ADtica%20de%20Protecci%C3%B3n%20de%20Datos%20Conciliaci%C3%B3n%20Carrier.pdf">Política de Privacidad</a>',
    },
    {
      id: 24,
      question: 'Tengo una duda, pero no aparece aquí.',
      answer: 'Póngase en contacto con el administrador al correo conciliacioncarrier@deloitte.com',
    },
  ];
  return (
    <Container sx={sectionStyles.container} ref={ref}>
      <Typography variant="h5" gutterBottom sx={sectionStyles.title}>
        Preguntas frecuentes
      </Typography>
      {dataFaq.map((item, index) => (
        <Accordion key={item.id}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index + 1}-content`}
            id={`panel${index + 1}-header`}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={sectionStyles.circle}>
                <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                  {index + 1}
                </Typography>
              </Box>
              <Typography variant="body1" sx={{ flex: 1 }}>
                {item.question}
              </Typography>
            </div>
          </AccordionSummary>
          <AccordionDetails>
            {item.answer ? (
              <Typography align="justify" dangerouslySetInnerHTML={{ __html: item.answer }} />
            ) : (
              <item.component />
            )}
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
});

export default FaqSection;
